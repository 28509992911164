/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/indent */
import { IFindPrice, IUserProfileImage } from '@/interfaces/common';
import { DateTimeFormatOptions } from '@/interfaces/assignment';
import { ACCESS_TOKEN, REGISTRATION_STATUS, PP_TOKEN } from '../common/common.constants';
import { accountUrl, environment, s3Url } from '../common/env.constants';
import { Browser, OS } from '../../GlobalEnums/enums';
/* eslint-disable no-restricted-syntax */

interface Registration {
  startDate: string;
  endDate: string;
}
const getRegistrationStatus = (registration: Registration | undefined): string => {
  let registrationStatus = '';
  if (registration && Object.keys(registration).length) {
    const currentDate = new Date();

    if (currentDate < new Date(registration?.startDate)) {
      registrationStatus = REGISTRATION_STATUS.BEGIN_SOON;
    } else if (
      currentDate >= new Date(registration?.startDate) &&
      currentDate < new Date(registration?.endDate)
    ) {
      registrationStatus = REGISTRATION_STATUS.OPEN;
    } else if (currentDate >= new Date(registration?.endDate)) {
      registrationStatus = REGISTRATION_STATUS.CLOSED;
    } else {
      registrationStatus = REGISTRATION_STATUS.OPEN;
    }
  }
  return registrationStatus;
};

const getCountryFromCache = (): string => {
  let userCountry = 'IN';

  if (typeof window !== 'undefined') {
    userCountry = window.sessionStorage.getItem('USER_COUNTRY') || 'IN';
  }

  return userCountry;
};

const renderCurrencySymbol = (): string => {
  let currentRegion;
  if (typeof window !== 'undefined') {
    currentRegion = getCountryFromCache();
  }
  if (currentRegion === 'US') return '$';
  return '₹';
};

type PricingObj = {
  IN: number;
  US: number;
  discount: number;
  couponDiscount?: number;
  isFree: boolean;
};

type ReturnPricingObj = {
  actualPrice: number;
  discount: number;
  discountPrice: number;
  couponDiscountPrice?: number;
  currentPrice: number;
  isCourseFree: boolean;
  currency: string;
};

const getPriceDetails = (pricing?: PricingObj, type = 'percent'): ReturnPricingObj | null => {
  let priceObject: IFindPrice;
  if (!pricing) {
    return null;
  }
  if (pricing.isFree && pricing.IN === 0 && pricing.US === 0) {
    priceObject = {
      actualPrice: 0,
      discount: 0,
      discountPrice: 0,
      currentPrice: 0,
      isCourseFree: true,
      currency: '',
    };
    return priceObject;
  }

  const currency = renderCurrencySymbol();

  const currentCountryData = getCountryFromCache();

  let currentRegion: string;
  if (currentCountryData) {
    currentRegion = currentCountryData;
  } else {
    currentRegion = 'IN';
  }

  let currentPrice = 0;
  let discountPrice = 0;
  let discount = 0;
  const isCourseFree = false;

  let actualPrice;
  if (currentRegion === 'US') {
    actualPrice = Number(pricing.US);
  } else {
    actualPrice = Number(pricing.IN);
  }

  if (type === 'percent') {
    discount = pricing.discount ? Number(pricing.discount) : 0;
    discountPrice = Number(actualPrice * (discount / 100));
    currentPrice = Number(actualPrice - discountPrice);
  } else if (type === 'actual') {
    discountPrice = pricing.discount ? Number(pricing.discount) : 0;
    currentPrice = Number(actualPrice - discountPrice);
  }

  if (pricing.couponDiscount) {
    const couponDiscountPrice = Number(pricing.couponDiscount);
    currentPrice -= couponDiscountPrice;
    priceObject = {
      actualPrice,
      discount,
      discountPrice,
      couponDiscountPrice,
      currentPrice,
      isCourseFree,
      currency,
    };
  } else {
    priceObject = {
      actualPrice,
      discount,
      discountPrice,
      currentPrice,
      isCourseFree,
      currency,
    };
  }

  return priceObject;
};

const decodeJwt = (token: string): typeof Object | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      Buffer.from(base64, 'base64')
        .toString('utf8')
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
};

const getAccessToken = (): string | null => window.localStorage.getItem(ACCESS_TOKEN);

const setAccessToken = (token: string): void => window.localStorage.setItem(ACCESS_TOKEN, token);

const removeAccessToken = (): void => localStorage.removeItem(ACCESS_TOKEN);

const removeRandomID = (): void => localStorage.removeItem('randomUid');

const clearPPToken = (): void => localStorage.removeItem(PP_TOKEN);

const useInitLocalstorage = () => {
  const clearLocalAndSessionStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
  };
  return {
    clearLocalAndSessionStorage,
  };
};

// removing keys preserving moengage only
const clearKeysFromLocalStorage = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (key && key !== 'MOE_DATA') {
      localStorage.removeItem(key);
      i--;
    }
  }

  sessionStorage.clear();
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.split('=');
    const name = eqPos[0];
    document.cookie = `${name}=;domain=.pwskills.com;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
  }
};

const logout = () => {
  removeAccessToken();
  removeRandomID();
  clearPPToken();
  deleteAllCookies();
  clearKeysFromLocalStorage();
  // (window as any)?.Moengage?.destroy_session();
  const newUrl = new URL(`${accountUrl}/login`);
  newUrl.searchParams.set('domain', window.location.host);
  newUrl.searchParams.set('redirectUrl', '/');
  newUrl.searchParams.set('action', 'logout');
  window.location.href = newUrl.href;
};

const logoutWithoutRedirect = () => {
  removeAccessToken();
  removeRandomID();
  clearPPToken();
  deleteAllCookies();
  clearKeysFromLocalStorage();
};

const encodeSlugUrl = (slug: string): string =>
  slug && slug.trim().split(' ').join('-')?.toLowerCase();

const decodeSlugUrl = (slug: string): string => slug && slug.trim().split('-').join(' ');

const findMinimumValue = (arr: number[]): number => {
  let min = arr[0];
  for (let i = 1; i < arr.length; i += 1) {
    if (arr[i] < min) {
      min = arr[i];
    }
  }
  return min;
};

export const getProfileUrl = (image: IUserProfileImage) => {
  if (!image) return '';
  const imagePrefix = `${s3Url}/user/profile_pictures`;

  if (image?.source === 'bucket') {
    return `${imagePrefix}/${image.link}`;
  }
  if (image?.source === 'oauth') {
    return image.link;
  }
  return image.link;
};

const campusEdgeCourseId =
  environment === 'dev'
    ? '64f06c53f897684c56e8079e'
    : environment === 'staging'
    ? '64f06ba38dbfbd417e74298a'
    : '64f06b16fe327fa724a20a8e';

export const appendZero = (value: number) =>
  value > 9 ? value.toString() : `0${value.toString()}`;

export const decodeBase64String = (utftext: string) => atob(utftext);

export const convertToHTMLObject = (htmlString: string): HTMLElement => {
  const element = document.createElement('div');
  element.innerHTML = htmlString;
  return element as HTMLElement;
};

const dateFormatter = (date: Date | string) => {
  let dateValue;
  if (date) {
    const findDate = new Date(date);
    const options: DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const formattedDate = findDate.toLocaleString('en-US', options);
    dateValue = formattedDate;
  }
  return dateValue;
};

const getCurrentTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getCurrentTimeInUTCByTimeZone = (timeZone: string) => {
  // const currentTime = new Date();
  return timeZone;
};

const hideorShowReveChat = (shouldShow: boolean) => {
  if (window !== undefined) {
    const revechatAPI = (window as any).$_REVECHAT_API;
    if (shouldShow) {
      // window?.$_REVECHAT_API?.Button?.show();
      return setTimeout(() => {
        revechatAPI?.Button?.show();
      }, 1000);
    }
    // window?.$_REVECHAT_API?.Button?.hide();
    return setTimeout(() => {
      revechatAPI?.Button?.hide();
    }, 1000);
  }
  return null;
};

const detectBrowserAndOS = () => {
  const { userAgent } = navigator;
  const { platform } = navigator;
  let browser = '';
  let os = '';

  // Detect browser
  if (userAgent.indexOf('Edg') > -1) {
    browser = Browser.Edge;
  } else if (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1) {
    browser = Browser.Chrome;
  } else if (userAgent.indexOf('Firefox') > -1) {
    browser = Browser.Firefox;
  } else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
    browser = Browser.Safari;
  } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
    browser = Browser.Opera;
  } else if (userAgent.indexOf('Trident') > -1 || userAgent.indexOf('MSIE') > -1) {
    browser = Browser.InternetExplorer;
  }

  // Detect OS
  if (platform.indexOf('Win') > -1) {
    if (userAgent.indexOf('Windows NT 10.0') > -1) {
      os = OS.Windows10;
    } else if (userAgent.indexOf('Windows NT 6.3') > -1) {
      os = OS.Windows8_1;
    } else if (userAgent.indexOf('Windows NT 6.2') > -1) {
      os = OS.Windows8;
    } else if (userAgent.indexOf('Windows NT 6.1') > -1) {
      os = OS.Windows7;
    } else if (userAgent.indexOf('Windows NT 5.1') > -1) {
      os = OS.WindowsXP;
    } else {
      // If none of the older versions match, assume it's Windows 11
      os = OS.Windows11;
    }
  } else if (platform.indexOf('Mac') > -1) {
    os = OS.MacOS;
  }

  return { browser, os };
};

const getHelpWidgetUrl = (
  token: string | null,
  randomId: string | null,
  lmsToken: string | null
) => {
  return `${process.env.NEXT_PUBLIC_HELP_CENTRE}/?token=${token}&random_id=${randomId}&app_version=2.0.0&client_type=WEB&coming_from=pw_skills&sub_org=PW_SKILLS&skills_lms_token=${lmsToken}`;
};
export const getCookies = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document?.cookie?.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export {
  hideorShowReveChat,
  getRegistrationStatus,
  getPriceDetails,
  renderCurrencySymbol,
  getCountryFromCache,
  decodeJwt,
  getAccessToken,
  setAccessToken,
  removeAccessToken,
  logout,
  logoutWithoutRedirect,
  dateFormatter,
  getCurrentTimeZone,
  getCurrentTimeInUTCByTimeZone,
  encodeSlugUrl,
  decodeSlugUrl,
  clearKeysFromLocalStorage,
  findMinimumValue,
  campusEdgeCourseId,
  detectBrowserAndOS,
  getHelpWidgetUrl,
  useInitLocalstorage,
};
