import AuthApi from '@/api/auth';
import useUserDetails from '@/store/userDetails';
import {
  clearKeysFromLocalStorage,
  getAccessToken,
  logout,
  removeAccessToken,
} from '@/utils/helper/helperFunction';
import { IUserProfileDetails } from '@/interfaces/common';
import { addMoEngageUser, initialiseMoengage } from '@/utils/initialiseMoEngage';
import { BooleanValue } from '@/GlobalEnums/enums';
import { kapturCustomerApiFlag } from '@/utils/common/env.constants';
import useGetUserAllCourses from './useGetUserAllCourses';
import useKapture from './useKapture';

const useGetUserProfileDetails = () => {
  const { getUserCoursesFunction } = useGetUserAllCourses();
  const { setProfileLoader, userDetails, setUserIsLoggedIn, setUserDetails } = useUserDetails();
  const addMoengageUserFunction = async (response: any) => {
    await addMoEngageUser(response);
  };
  const handleMoengage = async (response: any) => {
    const status = await initialiseMoengage();
    if (status === 'success') addMoengageUserFunction(response);
  };
  const { kaptureCustomerAPI } = useKapture();
  const getUserDetails = async (): Promise<IUserProfileDetails | string> => {
    return new Promise((resolve) => {
      const token = getAccessToken();
      if (token) {
        if (!userDetails.phoneNumber) {
          setProfileLoader(true);
          AuthApi.getUserProfile()
            .then(async (response) => {
              if (response.data) {
                handleMoengage(response);
                setUserDetails(response.data);
                setProfileLoader(false);
                resolve(response.data);
                getUserCoursesFunction();
                setUserIsLoggedIn(true);
                // this api will feed kapture with skills user data
                if (kapturCustomerApiFlag === BooleanValue.TRUE) kaptureCustomerAPI(response?.data);
              }
            })
            .catch(() => {
              setUserIsLoggedIn(false);
              setProfileLoader(false);
              setUserIsLoggedIn(false);
              removeAccessToken();
              clearKeysFromLocalStorage();
              logout();
            });
        }
      } else {
        setProfileLoader(false);
        setUserIsLoggedIn(false);
        setUserDetails({
          _id: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          isdCode: '',
          email: '',
          verified: false,
          createdAt: '',
          img: { link: '', source: '' },
        });
        resolve('No token found');
      }
    });
  };
  return { getUserDetails };
};
export default useGetUserProfileDetails;
