declare global {
  interface Window {
    dataLayer: {
      push: (args: Record<string, unknown>) => void;
    };
  }
}

export const dataLayer = (eventParams: Record<string, unknown>) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventParams);
  }
};

export const dummy = () => null;
